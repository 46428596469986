<!-- Display notes attached to a statement from Statements.vue -->
<template>
  <v-dialog :value="value" persistent width="1000">
    <v-card class="fill" height="800">
      <v-toolbar flat class="accent" color="main" dark>
        <v-toolbar-title>
          {{this.selectedStatement.name}} Notes
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-container class="pa-4"  style="border:1px solid grey; border-radius:5px; height:500px;overflow-y:auto">
          <v-card v-if='!this.selectedStatement.note' class="ma-2 pa-4" outlined>
            <v-card-text class="pa-8">
              <p class="black--text">No Notes Available</p>
            </v-card-text>
          </v-card>
          <v-card v-if='this.selectedStatement.note' class="ma-2 pa-4" outlined>
            <v-card-text class="pa-8">
              <p class="black--text">{{this.selectedStatement.note}}</p>
            </v-card-text>
          </v-card>
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { dateFormat } from '@/mixins/date-format'
import { displayAlert } from '@/mixins/displayAlert'

export default {
  name: 'StatementNotes',

  props: ['value', 'selectedStatement'],
  mixins: [displayAlert, dateFormat],

  async created () {
    this.nonTableHeight = 240
  },

  data () {
    return {
      note: {},
      notes:[],
      selected: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
      }
    }
  },
}
</script>
